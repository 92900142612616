.rbc-agenda-view {
  table.rbc-agenda-table {
    border: none;

    thead {
      display: none;
    }

    tbody > tr > td {
      padding: unset;
    }


    .rbc-agenda-time-cell {
      padding-left: 0;
      padding-right: 0;
      text-transform: unset;

      & > span {
        display: flex;
      }
    }

    .rbc-agenda-event-cell {
      display: none;
    }

    .rbc-continues-prior {
      &::before {
        display: none;
      }
    }

    .rbc-continues-after {
      &::after {
        display: none;
      }
    }

    tbody > tr > td + td {
      border-left: none;
    }

    .rbc-rtl & {
      tbody > tr > td + td {
        border-left-width: unset;
        border-right: none;
      }
    }

    tbody > tr + tr {
      border-top: none;
    }

    thead > tr > th {
      padding: unset;
      text-align: left;
      border-bottom: none;

      .rbc-rtl & {
        text-align: right;
      }
    }
  }
  .rbc-agenda-empty{
    margin: auto;
  }
}
