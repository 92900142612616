.rounded-image {
  height: 120px;
  width: 120px;
  border-radius: 999px;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}


.name-with-initials {
  text-align: left;
  display: flex;
  gap: 10px;
  align-items: center;
}


.name-initials {
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  color: $purple-800;
  overflow: hidden;
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  background-color: $purple-300;
  &.secondary{
    color: white;
    background-color: $grey-1000;
  }
}
.MuiAutocomplete-root{
  .name-initials {
    margin: 7px 5px 7px 3px;
  }
}