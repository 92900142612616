.collapsable-container {
  width: 100%;

  .collapsable-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  .collapsable-content {
    overflow: hidden;
    transition: height .4s;

    .collapsable-body {
      height: fit-content;
      width: 100%;
      padding-bottom: 50px;
    }
  }

  .extend-icon {
    transform: rotate(90deg);
    transition: .4s;

    &.extended {
      transform: rotate(-90deg);
    }
  }
}