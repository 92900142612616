form {
  .form-row {
    display: flex;
    gap: 10px;

    & > div {
      flex: 1;
    }
  }
  .form-inline-button {
    margin-bottom: 15px;
  }

  .switch-wrapper {
    margin: 10px 0;
  }
}


.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 16px;
}

.question-container {
  .multiple-options {
    color: $grey-500;
    font-size: 14px;
  }
  .list-choices {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    flex-wrap: wrap;
    .list-choice {
      border: 2px $grey-300 solid;
      padding: 10px 20px;
      border-radius: 18px;
      transition: border-color 0.3s;
      &.selected {
        border-color: #196DDD;
      }
    }
  }
  .error-message {
    color: $red-700;
    margin: 15px 0;
    display: block;
  }
}