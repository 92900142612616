.calendar-warning {
  padding: 16px;
  background-color: $red-200;
  color: $red-700;
  svg {
    color: $red-700;
    height: 16px;
  }
  border-radius: 12px;
  margin-bottom: 10px;
  .link-as-button{
    margin-top: 5px;
  }
}

.calendar-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;

  .navigation-actions {
    display: flex;
    gap: 10px;
  }

  .navigation-buttons {
    background-color: white;
    box-shadow: $surround-shadow-light;
    border-radius: 8px;
    display: flex;

    .button-container {
      &:first-child {
        border-right: solid 1px $grey-400;
      }
    }
  }

  .toolbar-dates {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
  }
}

.agent-date {
  padding: 10px 15px;
  color: white;
  background-color: $grey-1000;
  border-radius: 8px;
  margin-bottom: 8px;
}

.agent-event {
  flex: 1;
  padding: 15px;
  background-color: $grey-300;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .event-title {
    line-height: 1;
    display: flex;
    align-items: center;
    white-space: break-spaces;

    .event-pastille {
      min-width: 10px;
      height: 10px;
      width: 10px;
      border-radius: 999px;
      margin-right: 10px;

      &.blue {
        background-color: $purple-300;
      }

      &.pink {
        background-color: $pink-200;
      }

      &.yellow {
        background-color: $yellow-200;
      }
    }
  }

  .event-hours {
    color: $grey-600;
    margin-right: 10px;
    width: 115px;

    span {
      margin: 2px;
    }
  }
}

.agent-agenda-container {
  padding: 25px;
  width: 500px;

  .rbc-agenda-view {
    background: $grey-200;
    border-radius: 12px;
  }

  .rbc-agenda-content {
    padding: 9px;
  }

  .calendar-toolbar {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 5px;

    .toolbar-dates {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

