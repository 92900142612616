.parmameters-page {
  display: flex;
  gap: 20px;

  .parameters-list {
    flex: 2;
  }

  .parameters-form {
    flex: 3;
    overflow: hidden;

    .agency-paramters-form {
      display: flex;
      gap: 20px;

      & > div {
        &:nth-child(2) {
          flex: 1;
        }
      }
    }

    .info {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      font-style: italic;
      margin: 15px 0;

      p {
        margin: 0;
      }
    }

    .voice-mail-intro {
      border: 1px $grey-400 solid;
      background-color: $grey-100;
      max-width: 1040px;
      padding: 20px;
      border-radius: 15px;
    }
  }

  .step-parameter-container {
    cursor: grab;
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 10px;

    &:active {
      cursor: grabbing;
    }

    .material-textfield-wrapper {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .upload-button-container {
    position: absolute;
    bottom: 15px;
  }

  .settings-icon-btn {
    margin-bottom: 15px;

    svg {
      fill: $grey-700;
    }
  }

  .day-row-container {
    border-bottom: 1px black solid;
    padding: 10px 0;
    display: flex;
    align-items: center;

    .day {
      min-width: 150px;
    }
  }

  .input-row {
    display: flex;
    gap: 10px;

    & > div {
      flex: 1;
    }
  }

  .custom-audio-player {
    display: flex;
    gap: 15px;
    align-items: center;

    p, h5 {
      margin: 0;
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  .qualification {
    form {
      margin: 20px;
      margin-bottom: 40px;

      .situation {
        margin: 20px 0;

        p {
          margin: 0;
        }

        .material-textfield-wrapper {
          .MuiCheckbox-root {
            padding: 0 9px;
          }

          label {
            width: fit-content;
          }

          margin-bottom: 0;
        }

        .ratio {
          display: flex;
          gap: 10px;
          align-items: center;
          padding-left: 31px;

          .material-textfield-wrapper {
            margin-bottom: 0;

            input {
              width: 40px;
              text-align: center;
              padding: 8px 12px;
            }
          }

          .error {
            color: $red-500;
            font-weight: bold;
          }
        }
      }
    }

    table {
      margin-bottom: 30px;
    }
  }
}

.agent-list {
  border-radius: 18px;
  background-color: $grey-200;

  .material-select-wrapper {
    margin-bottom: 0;

    .MuiFilledInput-root {
      background-color: $grey-300;
    }
  }

  .notification-editor {
    .notification-name {
      flex: 1;
    }

    .MuiSelect-select {
      display: flex;

      .MuiListItemIcon-root {
        min-width: unset;
        margin-right: 10px;
      }
    }

    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  .agent-item {
    padding: 15px 30px;
    display: flex;
    align-items: center;

    span {
      flex: 5;
    }

    .role-container {
      flex: 2;
    }

    .role {
      width: fit-content;
      font-size: 14;
      color: $grey-500;
      padding: 4px 10px;
      border-radius: 5px;
      background-color: $grey-300;
    }
  }

  hr {
    height: 1px;
    border: none;
    background-color: $grey-400;
    margin: 0 35px;

    &:last-child {
      display: none;
    }
  }
}

.step-action {
  margin-top: 10px;
  display: flex;
  gap: 15px;
}

.add-agent-form {
  min-width: 350px;
}

.agency-opening-hours {
  position: relative;
  padding: 15px;
  border-radius: 10px;
  border: 1px $grey-400 solid;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 15px;

  .edit-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .opening-hour-row {
    display: flex;
    gap: 5px;
    padding: 5px 0;
    align-items: center;

    .material-textfield-wrapper {
      margin-bottom: 0;
    }

    .material-select-wrapper {
      margin-bottom: 0;
      flex: 1;
    }

    .opening-hours-selects {
      display: flex;
      gap: 10px;
      flex: 1;
    }

    & > div {
      min-width: 100px;
    }
  }
}

.opening-hours-btns {
  align-self: flex-end;
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 20px;

  button.cancel {
    border: 0;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.redirect-call-options-wrapper {
  margin: 20px 0;

  .MuiFormGroup-root {
    gap: 5px;
    flex-direction: column;

    & > label {
      padding-left: 40px;
    }
  }
}

.call-redirection-schema {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $grey-200;
  padding: 40px;
  position: relative;
  max-width: 1000px;
  border-radius: 15px;

  .redirection-schema-title {
    text-align: center;
    margin-top: 0;
  }

  .line {
    background-color: $grey-700;

    &.vertical {
      height: 50px;
      width: 1px;
    }

    &.horizontal {
      height: 1px;
    }
  }

  .branches {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 60px;
    position: relative;
    align-items: flex-start;

    .horizontal {
      position: absolute;
      top: 20px;
      width: 33%;
      max-width: 350px;
    }

    .opening {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      position: relative;
      overflow: hidden;

      .info {
        margin-bottom: 0;
      }

      .workflow {
        width: 100%;
        flex: 1;
        max-width: 400px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .workflow-step-container {
          width: 100%;
        }

        .workflow-step {
          padding: 20px;
          background-color: white;
          border-radius: 5px;
          box-shadow: $surround-shadow-extra-light;
          width: 100%;
          box-sizing: border-box;
          transition: transform 0.2s;

          &.condition {
            border-radius: 80px;
            background-color: $purple-200;
          }

          &.ring-duration {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;

            p {
              margin: 0;
            }

            .material-select-wrapper {
              margin-bottom: 0;

              .MuiFilledInput-root {
                min-width: unset;

                .MuiInputBase-input {
                  padding-top: 8px;
                }
              }
            }
          }

          &:hover {
            transform: translateY(-1px);
          }

          & > *:first-child {
            margin-top: 0;
          }

          & > *:last-child {
            margin-bottom: 0;
          }

        }

        .sms {
          word-break: break-word;
          padding: 10px;
          font-style: italic;
          font-size: 14px;
          background-color: $grey-200;
          border-radius: 10px;
        }
      }

      .vertical {
        position: absolute;
        left: 50%;
      }

      .box {
        border: 1px $grey-700 solid;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;

        .open-status {
          height: 10px;
          width: 10px;
          border-radius: 50%;

          &.is-open {
            background-color: $green-500;
          }

          &.is-closed {
            background-color: $red-500;
          }
        }
      }
    }
  }

  .forward-call-settings {
    margin-left: 0;
    margin-top: 50px;
    width: 100%;
  }

  .edit-btn {
    position: absolute;
    top: 15px;
    left: 15px;
  }

}

.opening-hours-settings {
  .MuiPaper-root {
    max-width: unset;
  }
}

.no-gli-modal {
  width: 90%;
  margin: auto;
  text-align: center;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      &:first-of-type {
        margin-top: 0;
      }
    }

    .link {
      text-decoration: underline;
      font-weight: bolder;
    }
  }
}

.pinel-table {
  margin: 20px 0px;
  border-right: 1px $grey-700 solid;
  border-bottom: 1px $grey-700 solid;

  .zones {
    .row {
      .td {
        &:first-of-type {
          background-color: white;
          border-left: 1px transparent solid;
          border-top: 0;
        }
      }

      .highlighted {
        background-color: #2f2f2f;
        color: white;
        width: 100%;
        text-align: center;
        font-weight: bolder;
      }

      &.all-zones {
        border-bottom: 1px $grey-700 solid;
        .td {
          font-weight: bolder;
        }
      }
    }
  }

  .tbody {
    .row {
      border-left: 1px $grey-700 solid;
      border-bottom: 1px #cdcdcd solid;

      .td {
        font-size: 14px;

        &:first-of-type {
          text-align: left;
        }
      }
    }
  }

  .row {
    display: flex;
    align-items: stretch;

    &:last-of-type {
      border-bottom: 0;
    }

    .td {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 100px;
      text-align: center;
      color: black;
      font-weight: normal;
      &:first-of-type {
        min-width: 300px;
        justify-content: flex-start;
      }

      &.with-background {
        background-color: #efefef;
      }

      &:not(:last-of-type) {
        border-right: 1px #2f2f2f solid;
      }
    }
  }
}
