$grey-1000: #222223;
$grey-900: #373737;
$grey-800: #5B5B5B;
$grey-700: #525252;
$grey-600: #7a7979;
$grey-500: #999999;
$grey-400: #DCDCDC;
$grey-300: #EFEFEF;
$grey-200: #F9F9F9;
$grey-100: #fbfbfb;

$green-700: #05603A;
$green-500: #38A149;
$green-300: #9bde8b;
$green-200: #DEF5D8;
$green-100: #ECFDF3;

$blue-200: #d8dff5;

$orange-400: #F6AD55;
$orange-300: #eec088;
$orange-200: #fadec3;
$orange-100: #e1d1c1;

$red-700: #d32f2f;
$red-500: #D36363;
$red-300: #e37777;
$red-200: #FFE1E1;

$purple-800: #633B48;
$purple-300: #EADDFF;
$purple-200: #F6F0FF;
$purple-bright: #5f02c0;

$pink-200: #FFD8E4;

$yellow-800: #A15C07;
$yellow-200: #FEF7C3;

$page-background: #F9FAFC;


$purple-200: #F6F0FF;

$surround-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$surround-shadow-light: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
$surround-shadow-mid-light: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.05);
$surround-shadow-extra-light: 0 3px 10px 0 rgba(0, 0, 0, 0.03);
