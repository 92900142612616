.agency-card {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  padding: 20px 10px;
  width: 100%;

  &:hover {
    background-color: $grey-300;
  }

  .rounded-image {
    height: 40px;
    width: 40px;
  }

  .agency-name {
    flex: 1;
    text-align: left;
    min-width: 200px;
  }
  .create-for-admin{
    font-size: 13px;
    color: $grey-700;
  }
}