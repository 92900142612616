.activity-tile{
  display: flex;
  padding: 10px;
  background-color: $grey-200;
  border-radius: 18px;
  align-items: center;
  margin-bottom: 10px;
  .activity-image{
    height: 60px;
    width: 60px;
    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    overflow: hidden;
    border-radius: 18px;
    margin-right: 10px;
  }
  .activity-content{
    display: flex;
    flex: 4;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    .client-name{
      font-size: 16px;
      font-weight: 500;
    }
    .activty-subtitle{
      display: flex;
      font-size: 12px;
      font-weight: 300;
      .activity-address{
        margin-left: 10px;
      }
      svg{
        margin-right: 3px;
        height: 12px;
      }
    }
  }
  .activity-workflowStep-container{
    flex: 2;
    .activity-workflowStep-wrap{
      // TODO find a better solution for this
    }
    .activity-workflowStep{
      background-color: $grey-400;
      color: $grey-800;
      width: fit-content;
      padding: 3px 8px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
  .activity-date{
    flex: 1;
      font-weight: 300;
  }
}


