.modification-row {
  display: flex;
  font-size: 13px;
  width: 100%;
  &.header-row{
    border-bottom: solid 1px white;
  }

  .modified-at {
    white-space: nowrap;
    font-style: italic;
  }

  .modified-value {
    width: 170px;
    padding: 5px 10px;
    word-break: break-word;
    &:nth-child(2){
      width: 90px;
    }
    &:nth-child(3){
      width: 70px;
    }
  }
}

.history-select-render {
  display: flex;
  align-items: center;

  svg {
    color: rgba(0, 0, 0, 0.54);
  }

  & > span {
    flex: 1;
  }
}

.danger-text{
  color: $red-700;
  .MuiFilledInput-root {
    color: $red-700;
  }
}
.history-tooltip{
  max-width: 500px !important;
}