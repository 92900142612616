.notes-container {
  padding: 7px;
  border-radius: 18px;
  background-color: $grey-200;

  .note-list {
    height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
  }

  .note-container {
    display: flex;

    &.is_author {
      justify-content: flex-end;
    }
  }

  .note {
    padding: 10px 15px;
    border-radius: 14px;
    background-color: $grey-300;
    font-size: 14px;
    margin-bottom: 10px;
    width: 85%;
    word-break: break-word;

    .note-header {
      display: flex;
      justify-content: space-between;

      .agent-name {
        font-weight: 500;
      }

      .note-date {
        font-size: 12px;
        font-weight: 300;
      }
    }

    .note-body {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .note-message {
        width: 100%;
        white-space: pre-line;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .is_author {
    .note {
      background-color: $blue-200;

    }
  }
}

.add-note-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  &.base-add{
    flex-direction: row;
    align-items: flex-start;
  }
  button{
    margin-left: 10px;
  }

  .material-textfield-wrapper {
    width: 100%;
    margin-bottom: 0;

    .MuiFilledInput-root {
      background-color: $grey-300;
    }
  }
}