.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $grey-200;
  min-height: 100vh;

  h1 {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .login-card {
    background-color: white;
    padding: 20px;
    border-radius: 18px;
    box-shadow: $surround-shadow-light;
    width: 350px;
    text-align: center;
    margin-bottom: 20px;

    button {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .agency-select {
    text-align: left;
    width: unset;
    max-width: 600px;
    .log-out-button {
      margin-left: 5px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .connection-button {
    width: 100%;
  }
}

.mail-error {
  color: $red-700;
}