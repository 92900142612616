.gli-pinel-tooltip {
  min-width: 600px;
  font-size: 14px !important;
  box-shadow: 0 0 10px rgba(black, 0.1);
  padding: 10px 20px !important;

  &.gli {
    background-color: white;
    color: black !important;
    font-weight: normal;
  }

  .main-info {
    margin: 20px 0;
    color: black;
    .info {
      display: flex;
      gap: 50px;
    }

    .table {
      .content {
        display: flex;
        padding: 10px 0;
        gap: 10px;
        font-weight: normal;
        div {
          width: 150px;
        }
        &.header {
          font-weight: bolder;
          border-bottom: 1px black solid;
        }
      }
    }

    & > div {
      margin: 15px 0;
    }

    .name {
      font-size: 18px;
    }

    .price {
      color: $grey-700;
    }

    .details-table {
      padding-left: 20px;
      margin: 20px 0;
      width: fit-content;

      .row-situation {
        display: flex;
        gap: 10px;
        margin: 10px 0;
        align-items: center;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -10px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: black;
        }

        .revenues {
          width: 100px;
        }

        .situation {
          width: 220px;
        }

        .status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 60px;

          &.yes {
            color: $green-500;
          }

          &.no {
            color: $red-500;
          }
        }

        &.contract-not-activated {
          position: relative;
          color: $grey-500;

          .status {
            color: $red-500;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: $grey-500;;
          }

          &::before {
            background-color: $grey-500;;
          }
        }

        &.contract-activated {
          .status {
            svg {
              color: $green-500;
            }
          }
        }
      }
    }
  }

  &.pinel {
    background-color: white;

    p {
      color: black;
      font-weight: normal;
    }

  }

  .result {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    margin: 15px 0;
  }


  p {
    &.ok {
      color: $green-500;
    }
    &.not-ok {
      color: $red-300;
    }
  }
}
