.file-preview {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 150px;
  box-shadow: $surround-shadow-light;
  cursor: pointer;
  .preview-actions-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .preview-actions {
      display: flex;
      background-color: white;
      border-radius: 0 0 8px 8px;
      box-shadow: $surround-shadow-light;
      border-top: none;
    }

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.comment-container {
  display: flex;
}

.comment {
  padding: 10px 15px;
  border-radius: 14px;
  background-color: $grey-300;
  font-size: 14px;
  margin-bottom: 10px;
  word-break: break-word;
  max-width: 300px;
  .comment-header {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    & > div{
      &:first-child{
        white-space: nowrap;
        font-weight: 500;
        flex: 1;
      }
    }
    .comment-date {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .comment-body {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .comment-message {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}


.document-preview-container {
  box-shadow: $surround-shadow-light;
  overflow: hidden;
  position: relative;

  .comment-preview {
    padding: 55px 10px 10px;
  }
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.document-section-list {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .file-upload-button-container {
    svg {
      height: 50px;
      width: 50px;
    }
  }
}

.file-display {
  overflow: auto;
  max-width: 90vh;
  max-height: 90vh;

  .document {
    height: 90vh;
    width: 90vh;
    border: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.create-group-button-container {
  padding: 20px;
  text-align: center;
}

.document-card-container{
  display: flex;
  gap: 10px;
  padding-right: 20px;
  align-items: center;
  .document-card{
    padding: 10px;
    border-radius: 8px;
    border: solid 1px $grey-300;
    box-shadow: $surround-shadow-light;
    .document-card-header{
      display: flex;
      margin-bottom: 10px;
      .documents-status{
        flex: 1;
        span{
          margin-left: 5px;
          font-size: 13px;
          color: $grey-700;
        }
      }
    }
    .document-card-actions{
      gap: 10px;
      display: flex;
      margin-top: 10px;
    }
  }
}