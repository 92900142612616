.agenda-event-dates {
  display: flex;

  .date-icon {
    text-align: center;
    background-color: $pink-200;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    color: $purple-800;
    margin-right: 20px;

    .day-number {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .event-hours {
    display: flex;
    align-items: center;
    margin-top: 7px;

    svg {
      font-weight: 500;
      height: 16px;
    }
  }
}


.agenda-page-event {
  flex: 1;
  padding: 8px;
  box-shadow: $surround-shadow-light;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.agenda-visit-slot {
  .agenda-property-item {
    border-bottom: none;
    gap: 10px;
    padding: unset;
    margin-left: 60px;
    flex: 1;
    text-align: left;

    .property-price {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .property-address {
      margin-top: 5px;
    }

    .property-image {
      height: 40px;
      width: 40px;
      margin-right: 0;
      border-radius: 8px;
    }
  }

  .booked-visit-container {
    padding: 6px 10px;
    background: $grey-200;
    color: $grey-700;
    border-radius: 4px;

    &.full {
      background: $green-100;
      color: $green-700;
    }
  }
}
.agenda-page-selector{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-content{
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .agent-agenda-container{
    flex: 1;
    overflow: hidden;
  }
}

.drawer-header {
  display: flex;
  border-bottom: solid 1px $grey-300;
  padding: 20px;
  align-items: center;

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
  }

  .drawer-filler {
    flex: 1;
  }

  .button-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.agenda-page{
  flex: 1;
  overflow: hidden;
  .rbc-calendar{
    overflow: hidden;
    .rbc-agenda-view {
      padding: 20px 5px;
    }
  }
}