.integration-tile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: auto;
}

.integration-tile {
  border-radius: 20px;
  border: solid 1px $grey-200;
  overflow: hidden;
  max-width: 350px;
  min-width: 350px;

  .integration-header {
    background: linear-gradient(180deg, #ffffff, $grey-200);
    display: flex;
    justify-content: center;
    padding: 30px;

    .image-container {
      height: 120px;
      width: 120px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      box-shadow: $surround-shadow-extra-light;

      img {
        height: 50px;
      }
    }
  }

  .integration-body {
    padding: 24px;
    background-color: $grey-200;
    color: $grey-600;
    height: 100%;

    .log-out-button {
      text-decoration: underline;
      margin-top: 20px;
    }

    .integration-done-text {
      text-align: center;
    }

    .integration-done-icon {
      text-align: center;

      svg {
        color: $green-500;
        height: 30px;
        font-size: 40px;
        margin-bottom: 10px;
      }
    }

    h3 {
      color: black;
      font-size: 18px;
      font-weight: 500;
    }

    .integration-description {
      margin-bottom: 30px;
    }

  }
}