.dropzone {
  min-height: 200px;
  border: 1px $grey-500 dashed;
  cursor: pointer;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  min-width: 400px;
  align-items: center;

  .input-placeholder {
    text-align: center;
    width: 100%;
    height: 100%;
    color: $grey-700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      color: $grey-500;
      font-size: 60px;
    }
  }

  &:hover {
    border: 1px $grey-500 dashed;

    .input-placeholder {
      color: $grey-500
    }
  }

  .thumbnail-upload-container {
    height: 190px;
    width: 190px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dashed 1px $grey-500;
    text-align: center;

    svg {
      color: $grey-500;
      font-size: 60px;
    }
  }

  .thumbnail-container {
    height: 190px;
    width: 190px;
    box-shadow: $surround-shadow;
    position: relative;

    .spinner-container {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(130, 130, 130, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      color: white;
    }

    .delete-button {
      position: absolute;
      top: 5px;
      right: 5px;

      svg {
        color: white;
        background-color: $grey-500;
        border-radius: 999px;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.upload-button-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-image-zone {
  display: block;

  .sortable-list {
    max-width: 800px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px
  }
}

