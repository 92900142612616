.agency-root {
  display: flex;
  height: 100vh;
  overflow: hidden;
  .responsive-msg {
    padding: 30px;
    margin: auto;
    text-align: center;
    .logo-wrapper {
      width: 50%;
      min-width: 200px;
      max-height: 100px;
      margin: 20px auto;
      .logo {
        height: 100%;
        width: 100%;
      }

    }
  }
}

.page-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .page-header {
    padding: 20px 20px 10px;

    .material-select-wrapper {
      margin-bottom: 0;
    }

    .material-textfield-wrapper {
      margin-bottom: 0;
    }
  }

  .page-actions {
    display: flex;
    justify-content: space-between;

    .actions-container {
      display: flex;
      gap: 10px;
      align-items: center;

      button{
        height: 100%;
      }
      a {
        height: 100%;
        box-sizing: border-box;
        background-color: $grey-200;
        color: $grey-1000;
        padding: 15px 25px;
        border-radius: 18px;
        line-height: 1.75;
        &:hover {
          background-color: $grey-300;
        }
      }
    }
  }

  .page-header-row {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .page-actions-container {

  }

  .page-body {
    flex: 1;
    padding: 20px 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &.details {
      gap: 2rem;
    }
  }
}

.back-button {
  display: flex;
  align-items: center;

  &.link {
    background-color: transparent;
    color: black;
    box-shadow: none;

    &:hover{
      background-color: transparent;
      box-shadow: none;
    }
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
}

.page-selector-container{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;

  .page-selector {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    line-height: normal;
    font-size: 16px;
    font-weight: normal;
    &.selected {
      background-color: $grey-300;
    }
  }
}

.MuiPopover-paper {
  border-radius: 18px !important;

  .material-select-wrapper {
    padding: 10px;
    margin-bottom: 0;
  }
}

.menu-links-container {
  .menu-link {
    margin-right: 15px;
    &.selected {
      text-decoration: underline;
    }
  }
}

.candidate-menu {
  margin-top: 0;
}