.property-list-item {
  display: flex;
  align-items: center;
  color: $grey-1000;
  padding: 10px 0;
  margin: 0 20px;
  gap: 20px;
  border-bottom: solid 1px $grey-300;

  svg {
    margin-right: 3px;
    height: 14px;
  }

  .property-steps-status {
    display: flex;
    flex-wrap: wrap;
  }

  .property-info {
    flex: 4;
    display: flex;
    font-size: 14px;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
  }

  .property-dates {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .property-steps-status {
    flex: 4;
    gap: 15px;
  }

  .property-image {
    position: relative;
    height: 110px;
    width: 110px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    overflow: hidden;
    border-radius: 18px;
    margin-right: 10px;

    .image-tag-container {
      position: absolute;
      left: 10px;
      top: 10px;

      .image-tag {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 10px;

        &.success {
          color: $green-500;
          background-color: $green-200;
        }

        &.error {
          color: $red-500;
          background-color: $red-200;
        }
      }
    }
  }

  .property-address {
    margin-top: 10px;
  }

  .menu-container {
    svg {
      height: 1em;
      margin-right: unset;
    }
  }

  .property-identifier {
    flex: 1;
    color: $grey-1000;
    text-align: center;
    font-size: 0.8rem;
  }

  .property-link {
    border-radius: 999px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      padding-top: 7px;
      padding-left: 2px;
    }

    &:hover {
      background-color: $grey-300;
    }
  }
}

.property-price {
  color: $grey-900;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;

  .price-month {
    font-size: 13px;
    font-weight: 100;
  }
}

.step-status {
  margin: 4px 0;

  span {
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 14px;

    &:first-child {
      background-color: $grey-300;
      margin-right: 4px;
      font-size: 12px;
      font-weight: 500;
    }

    &:nth-child(2) {
      color: $grey-800;
      background-color: $grey-400;
    }

  }
}

.visit-modal-content {
  width: 460px;
  height: 600px;

  .candidature-visits {
    .agenda-visit {
      width: unset;
    }
  }
}

.property-form-header {
  display: flex;
  gap: 10px;

  .property-step-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    height: 235px;
    writing-mode: vertical-lr;

    & > div {
      writing-mode: horizontal-tb;
    }

    .link-action {
      writing-mode: horizontal-tb;
      width: fit-content;
      height: fit-content;
    }
  }
}

.image-property-container {
  width: 235px;
  height: 235px;
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.google-map-container {
  border-radius: 18px;
}

.property-card {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  padding: 0;
  margin: 0;
  text-align: left;

  .property-image {
    border-radius: unset;
  }

  .property-identifier {
    margin: 0 30px;
    font-size: 20px;
    font-weight: 500;
  }
}

.add-image-icon-button-container {
  position: absolute;
  right: 10px;
  top: 10px;

  .add-image-icon-button {
    svg {
      height: 25px;
      width: 25px;
      padding: 3px;
    }
  }
}


.property-list-item {
  &.property-selector {
    flex: 1;
    padding: 10px 0;
    margin: 0;

    .property-price {
      margin-bottom: 0;
    }

    .property-address {
      margin-top: 0;
    }

    .property-image {
      height: 60px;
      width: 60px;
    }

    .image-tag-container {
      .image-tag {
        padding: 0;
        height: 13px;
        width: 13px;
        border-radius: 10px;
      }
    }
  }
}

.pastille {
  height: 13px;
  width: 13px;
  border-radius: 999px;

  &.success {
    background-color: $green-200;
  }

  &.error {
    background-color: $red-200;
  }
}

.archive-link {
  margin-top: 10px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.property-detail-page {
  display: flex;
  gap: 15px;

  & > div {
    flex: 2;

    &:first-child {
      flex: 3;
    }
  }

  .qualification {
    a {
      text-decoration: underline;
      display: block;
      margin: 20px 0;
    }

    .gli-property-setting {
      display: flex;
      gap: 10px;
    }
  }
}

.property-visit-slot-container {
  display: flex;
  gap: 10px;

  .property-visit-slots {
    flex: 1;

    .agenda-page-event {
      margin: 0 0 15px;
      width: 100%;

      .booked-visit-container {
        margin-left: 10px;
      }
    }
  }
}

.visits-actions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  gap: 10px;

  .create-slot-button {
    flex: 1;
  }
}


.property-visit-container {
  padding: 10px 20px;

  .candidate-name {
    padding-right: 10px;
  }
}

.property-actions {
  .archived {
    background-color: $red-200;
  }

  .offline {
    background-color: $orange-200;
  }

  .online {
    background-color: $green-200;
  }

  .dropdown {
    display: flex;
    align-items: center;
    gap: 10px;

    &.arrow-upside-down {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.property-gli-summary {
  min-width: 400px;
  font-size: 14px !important;
  font-weight: normal !important;

  .popover-gli {
    .content {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &:first-of-type {
          font-weight: bolder;
        }

        &:last-of-type {
          width: 250px;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

.pinel-summary {
  min-width: 800px;
  background-color: white !important;
  border: 1px $grey-300 solid;
  padding: 20px !important;
  box-shadow: 0 0 20px rgba(black, 0.1);
  transform: translateX(50px) !important;
  font-weight: normal;
  font-size: 14px !important;
  span {
    color: black;
    font-size: 14px;
  }

  p {
    font-size: 16px;
    color: black;
  }
}
