.modal-background{
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 50px;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  &.show{
    display: block;
    &.centered{
      display: flex;
      align-items: center;
    }
  }

  .modal-content {
    position: relative;
    background-color: white;
    margin: auto;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    .header-container{
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: solid 1px #d4d4d4;
    }
  }
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}