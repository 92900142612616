.visit-slot-form {
  padding: 10px 25px;
}

.visit-slot-informations-container{
  padding: 0 25px;
  button{
    text-decoration: underline;
  }
}

.visit-slot-modal {
  overflow: hidden;
  display: flex;
  .information {
    border-radius: 8px;
    background-color: $purple-200;
    margin-bottom: 15px;
    padding: 7px 16px;
  }
  & > div {
    overflow-y: auto;

    &:first-child {
      border-right: solid 1px $grey-300;
    }
  }
}
.property-visit-slot-list{
  padding: 10px 20px;
}

.possible-visit-slot-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: $grey-700;
  padding: 5px 0;
}

.visit-slot-reservation-container {
  background-color: $grey-200;
  padding: 8px;
  border-radius: 12px;
  margin-bottom: 15px;

  .add-visit-button {
    width: 100%;
    margin: 10px 0;
  }

  .candidature-tile {
    padding: 8px;
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    background-color: $grey-300;
    border-radius: 12px;
    margin-top: 4px;


    .action-container {
      display: flex;
      gap: 10px;
    }
  }
}


.candidate-select-popover {
  padding: 15px;
  min-width: 400px;

  .material-select-wrapper {
    padding: 0;
    margin-bottom: 15px;
  }
}

.missing-visit-slot-modal {
  padding: 25px;
  text-align: center;
  color: $grey-800;

  & > img {
    height: 70px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: $grey-1000;
  }

  .missing-slot-message {
    margin-bottom: 30px;
  }

  .candidate-button-container {
    width: 100%;
    margin-bottom: 10px;

    .candidate-button {
      background-color: $grey-200;
      border-radius: 12px;

      &:hover {
        background-color: $grey-300;
      }
    }
  }
  .missing-button-container {
    width: 100%;
    margin-bottom: 10px;

    .missing-button {
      padding: 15px;
      border-radius: 12px;
      width: 100%;
      text-align: left;
      background-color: $grey-200;

      .property-list-item {
        border-bottom: none;
      }

      &:hover {
        background-color: $grey-300;
      }
    }
  }
}
.property-selector-container{
  min-width: 400px;
  padding: 15px;
}