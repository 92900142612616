.tile{
  background-color: $grey-200;
  border-radius: 18px;
  padding: 30px 0;
  flex: 1;
  max-width: 250px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  .tile-number{
    font-size: 61px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.tiles-container{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}