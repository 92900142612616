.plan-premium-modal {
  .link-as-button {
    margin-left: 10px;
  }
}
.success-modal{
  padding: 90px 60px;
  text-align: center;
  .success-title{
    font-size: 30px;
  }
  .agent-name{
    display: flex;
    align-items: center;
    gap: 5px;
    svg{
      height: 14px;
    }
  }
}
.member-modal{
  text-align: left;
}

.current-plan-tile {
  background-color: $grey-200;
  border-radius: 12px;
  width: fit-content;
  font-size: 15px;

  padding: 15px 60px 15px 15px;

  .current-plan-tile-header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    h3{
      color: $grey-900;
      margin: 0;
    }
    .current-plan {
      font-size: 18px;
      font-weight: 500;
      color:  $purple-bright;
      padding: 8px;
      border-radius: 6px;
      background-color: $purple-200;
    }
  }
  .plan-message{
    font-weight: 500;
    margin-top: 10px;
  }
}

.plan-payement-tile{
  min-height: 355px;
  margin-top: 30px;
  .plan-payement-header{
    font-size: 35px;
    font-weight: 500;
    display: block;
    padding-bottom: 0;
    h3{
      margin: 0;
    }
  }
  .payement-price{
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    gap: 5px;
    .price-sign{
      line-height: 22px;
      font-size: 25px;
      font-weight: 500;
    }
    .price-value{
      font-size: 45px;
      line-height: 35px;
      font-weight: 600;
    }
    .price-range{
      font-size: 22px;
      font-weight: 500;
    }
  }
  .integration-body{
    height: unset;
  }
  .message{
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    gap: 10px;
    svg{
      height: 20px;
    }
  }
  .action-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
