.filter-header{
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  .agent-select{
    max-width: 250px;
  }
}

.adjustment{
  svg{
    height: 20px;
  }
}

