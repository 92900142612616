.google-map-container {
  height: 100%;
}

.candidate-tooltip {
  pointer-events: all;
  font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  background-color: white;

  .candidate-tooltip-button {
    &.selected {
      color: $green-200;
    }
  }

  border-radius: 999px;
}

.candidate-list-card-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 999;
}

.map-candidate-card {
  display: flex;
  height: 150px;
  border-radius: 10px;
  margin: 20px 10px;
  box-shadow: $surround-shadow;
  background-color: white;
  overflow: hidden;

  .candidate-card-content {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex: 1;

    .candidate-card-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $grey-300;
      font-size: 13px;
    }
  }

  .image-container {
    img {
      object-fit: cover;
      width: 25vw;
      max-width: 150px;
      height: 100%;
    }
  }

  .candidate-card-dimensions {
    display: flex;
    align-items: center;

    svg {
      height: 15px;

      &:nth-child(2) {
        margin-left: 7px;
      }
    }
  }

  svg {
    height: 15px;
    margin-right: 5px;
  }
}

.list-tile {
  height: 300px;
  width: 300px;
  background-color: white;
  pointer-events: all !important;
  z-index: 9999;
  box-shadow: $surround-shadow;
}

.gm-style {
  .gm-style-iw-t {
    animation-name: appear;
    animation-duration: 0.1s;
  }

  .custom-tooltips.gm-style-iw-t {
    &:after {
      display: none !important;
    }

    .gm-style-iw-c {
      transform: translate3d(-50%, -20px, 0);
      padding-right: 30px !important;
      padding-bottom: 30px !important;
      padding-top: 40px !important;
      padding-left: 30px !important;

      max-width: unset !important;
      max-height: unset !important;
      background: unset;
      box-shadow: unset;
      border-radius: unset;

      & > button {
        display: none !important;
      }

      .gm-style-iw-d {
        overflow: auto !important;
        max-width: unset !important;
        max-height: unset !important;
      }
    }
  }
}

//this animation is used to hide the delay when we may add a custom classname to a tooltip
@keyframes appear {
  from {
    opacity: 0
  }
  90% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.carrousel-container {
  width: 250px;

  .slide {
    display: flex;
  }
}

.map-desktop-card {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
  margin: 10px;
  background-color: white;
  overflow: hidden;
  flex-direction: column;
  text-align: start;

  .candidate-card-content {
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    flex: 1;

    .candidate-card-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $grey-1000;
      font-size: 13px;
    }
  }

  .image-container {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 150px;
    }
  }

  .candidate-card-dimensions {
    display: flex;
    align-items: center;

    svg {
      height: 15px;

      &:nth-child(2) {
        margin-left: 7px;
      }
    }
  }

  svg {
    height: 15px;
    margin-right: 5px;
  }
}

.map-marker {
  background-color: white;
  padding: 10px;
  border-radius: 999px;
  box-shadow: $surround-shadow;
  pointer-events: all;
  cursor: pointer;

  &.selected {
    color: $green-200 !important;
    position: relative;
  }
}

