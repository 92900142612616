.agenda-visit {
  .candidate-name {
    text-align: left;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 40px;
    flex: 1;

    .candidate-initials {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      color: $purple-800;
      background-color: $purple-300;
    }

    .candidature-email {
      font-size: 14px;
      color: $grey-700;
    }
  }


  .agenda-event-dates {
    .date-icon {
      background-color: $yellow-200;
      color: $yellow-800;
    }
  }

  &.canceled {
    .agenda-event-dates {
      .date-icon {
        background-color: $grey-400;
        color: $grey-800;
      }
    }
  }

  .property-address {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;

    svg {
      height: 18px;
    }
  }
}

.visit-modal {
  h2 {
    margin-bottom: 0;
    padding-bottom: 0;

  }

  .visit-slot-form {
    padding-top: 0;
  }

  .visit-title {
    display: flex;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
  }

  .visit-dates {
    margin-bottom: 15px;
    font-size: 14px;
    color: $grey-1000;
    display: flex;
    align-items: center;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
  }

}

.create-candidate-button-container {
  width: 100%;
  margin-bottom: 15px;

  button {
    width: 100%;
  }
}

.candidate-information {
  .link-as-button {
    margin: 10px 0;
  }

  h4 {
    font-weight: 400;
    margin: 0;
    color: $grey-700;
  }

  .information-row {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    .information-data {
      flex: 1;
      padding: 10px;
      background-color: $grey-200;
      border-radius: 12px;

      .information-header {
        font-size: 12px;
        color: $grey-700;
      }
    }
  }
}

.candidate-visit-modal {
  img {
    height: 64px;
    width: 64px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  .candidate-visit-modal-button {
    display: flex;
    padding: 15px 25px;
    font-size: 14px;
    color: $grey-700;
    background-color: $grey-200;
    border-radius: 16px;
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    max-width: 470px;
    gap: 10px;

    &:hover {
      background-color: $grey-300;
    }

    .icon-container {
      height: 50px;
      width: 50px;
      min-width: 50px;
      border-radius: 999px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .button-title {
      margin-bottom: 10px;
      color: $grey-900;
      font-weight: 500;
      font-size: 18px;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      background-color: $grey-300;
      color: $grey-500;

      .button-title {
        color: $grey-500;
      }
    }
  }
}