.snack-bar-container {
  padding: 20px 20px 0;

  .snack-bar {
    padding: 10px 20px;
    background-color: $grey-1000;
    color: white;
    border-radius: 18px;
    display: flex;
    gap: 15px;
    align-items: center;

    .snack-bar-body {
      flex: 1;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}