.kanban-actions{
 background-color: $purple-200;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: 15px;
}

.candidate-kanban-container {
  display: flex;
  overflow-x: auto;

  .kanban-section {
    background-color: $grey-200;
    border-radius: 18px;
    padding: 10px;
    margin: 10px;
    width: 280px;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .section-header {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 1px;

      .section-count {
        padding: 5px 10px;
        background-color: $grey-300;
        border-radius: 6px;
        margin-left: 10px;
      }
    }

    .sortable-list {
      flex: 1;
      padding-bottom: 40px;
      overflow: auto;
    }
  }

  .kanban-item {
    display: block;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 18px;
    border: solid 1px $grey-400;

    .candidate-menu{
      display: none;
      margin-right: 2px;
    }
    &:hover,&.menu-open{
      .last-modification-date{
        display: none;
      }
      .candidate-menu{
        display: block;
      }
    }

    &.disable-link{
      color: $grey-500;
      pointer-events: none;
      .tag-container {
        .contact-tag {
          background-color: $grey-300;
          color: $grey-500;
        }
      }
    }

    &.disabled {
      background-color: $red-200;
      color: $grey-500;
    }

    .card-row {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;

      &.card-body {
        padding: 0 10px 10px;
      }
    }

    .contact-name-container {
      display: flex;
      align-items: flex-start;
      .contact-name {
        max-width: 145px;
        cursor: pointer;
        padding-top: 12px;
        padding-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        word-break: break-word;
      }
    }

    .last-modification-date {
      font-weight: 300;
      font-size: 12px;
      white-space: nowrap;
      margin-right: 10px;
    }

    .monthly-income {
      & > div {
        font-weight: 600;
        font-size: 16px;

        &:first-child {
          font-weight: 300;
          font-size: 9px;
        }
      }
    }

    .candidate-notation {
      font-size: 30px;
    }
  }

  .sortable-ghost {
    opacity: 0.3;
  }
}

.tag-container {
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 5px;

  .candidate-notation {
    float: right;
    height: 24px;
    display: flex;
    align-items: flex-end;
  }

  .contact-tag {
    padding: 3px 5px;
    background-color: $grey-400;
    color: $grey-800;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    float: left;
    margin: 2px;

    svg {
      height: 16px;
      color: $orange-400;
    }

    &.gli-pinel {
      color: white;
      &.ok {
        background-color: $green-500;
      }
      &.not-ok {
        background-color: $red-300;
      }
      svg {
        color: white;
      }
    }
  }
}


.candidate-tab-container {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.candidate-row-container {
  overflow: auto;
}

.candidate-tab {
  border-radius: 18px;
  padding: 7px;
  background-color: $grey-200;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .tab-row {
    display: flex;
    background-color: $grey-300;
    border-radius: 10px;
    margin-bottom: 3px;
    border: solid 1px $grey-400;

    .material-select-wrapper {
      margin: 5px 0px;
    }

    &.disabled {
      background-color: $red-200;
      color: $grey-500;
    }

    & > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px 10px;
      border-right: solid 1px $grey-400;
      flex: 1;
      min-width: 75px;
      text-align: center;

      &:nth-child(1) {
        flex: 2;
        min-width: 200px;
        word-wrap: break-word;
        text-align: left;
      }

      &:nth-child(2) {
        flex: 1.5;
        padding: 0 10px;
        min-width: 200px;

      }

      &:nth-child(3) {
        font-size: 40px;
        padding: 0 10px;
      }

      &:nth-child(4) {
      }

      &:nth-child(5) {
        .tag-container {
          justify-content: center;
        }
      }

      &:nth-child(6) {
        .tag-container {
          justify-content: center;
        }
      }

      &:nth-child(7) {
      }

      &:last-child {
        border-right: none;
      }
    }

    &.row-header {
      border: none;
      background-color: transparent;
      align-items: center;

      & > div {
        padding: 10px 10px;
        font-size: 16px;
        text-align: center;
        border-right: none;
      }
    }
  }
}

.candidate-details-page {
  display: flex;

  h5 {
    margin-top: 15px;
  }

  & > div:first-of-type {
    flex: 4;
  }

  & > div:last-of-type {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-right: 2rem;
  }

  gap: 3rem;
}

.candidature-radio-container {
  background-color: $grey-200;
  border-radius: 14px;
  width: fit-content;
  padding: 5px;

  .MuiTypography-root {
    border-radius: 14px;
    font-size: 50px;
    background-color: $grey-300;
  }

  .radio-selected {
    .MuiTypography-root {
      border-radius: 14px;
      background-color: white;
      border: solid 1px $grey-300;
    }
  }
}

.summary {
  display: flex;
  padding: 30px;
  flex-direction: column;
  gap: 30px;
  background: $grey-200;
  border-radius: 18px;
}

.warning-message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px $grey-400 solid;

  .icon-warning {
    color: $orange-300;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
  }
}

.candidacy-summary {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 15px;
  border-bottom: 1px $grey-400 solid;

  .candidature-summary-text {
    font-size: 12px;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div > * {
    margin-top: 0;
  }

  h5 {
    margin-bottom: 8px;
    font-size: 15px;
  }

  ul {
    padding: 15px;
    list-style: circle;
    text-align: left;
    margin-bottom: 0;

    li {
      margin-bottom: 5px;
    }
  }
}

.candidature-header {
  display: flex;
  align-items: center;

  .candidature-main-occupant-name {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;

    .relationship-image {
      height: 60px;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}


.property-select {
  display: flex;
  flex-direction: column;

  a {
    align-self: flex-end;

    &:hover {
      text-decoration: underline;
    }

    div {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        transform: scale(0.75);
      }

      p {
        margin: 0;
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }
}

.candidature-visits{
  .agenda-visit{
    background-color: white;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .property-address{
    margin-left: 40px;
  }
}