.dashboard-content{
  margin-top: 10px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  flex: 1;
  .activity-container{
    flex: 3;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .activity-content{
    overflow: auto;
  }
  .statistiques-container{
    flex: 2;
  }
}

.chart-container{
  padding: 10px;
  background-color: $grey-200;
  border-radius: 18px;
}
