
.plan-progress-container {
  margin: 20px 0;
  width: 100%;

  .MuiLinearProgress-colorPrimary {
    height: 10px;
    background-color: $orange-100;
    border-radius: 999px;
    overflow: hidden;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: $orange-400;
  }

  .plan-subtitle {
    font-size: 12px;
    font-weight: 300;
    margin-top: 3px;
  }
}

.nav-bar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
  align-items: flex-start;

  .plan-info-container {
    background-color: black;
    border-radius: 18px;
    padding: 15px;
    margin-bottom: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .plan-subtitle {
      font-size: 12px;
      font-weight: 300;
      margin-top: 3px;
    }

    .plan-info {
      text-align: center;
      margin-top: 7px;
    }
  }

  .nab-bar-header {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;

    img {
      width: 170px;
    }
  }

  .nav-bar-bottom-container {
    width: 200px;
  }

  .nav-bar-items {
    flex: 1;
  }

  .nav-item {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    color: $grey-500;
    border-radius: 18px;
    width: 180px;
    font-weight: 500;
    font-size: 16px;

    span {
      flex: 1;
    }

    svg {
      height: 19px;
      margin-right: 10px;
      width: 19px;
    }

    &.active {
      background-color: $grey-300;
      color: black;
    }
  }

  .log-out-button {
    margin-top: 30px;
    text-decoration: underline;
    color: $grey-500;
  }
}

.agent-card {
  cursor: pointer;
  background-color: $grey-200;
  display: flex;
  gap: 10px;
  border-radius: 18px;
  padding: 10px 5px;
  align-items: center;
  word-break: break-word;

  a {
    width: 100%;
  }

  .agent-informations {
    flex: 1;
    max-width: 100px;

    .agent-name {
      font-size: 14px;
      color: $grey-500;
    }
  }

  .rounded-image {
    height: 40px;
    width: 40px;
  }
}