.material-textfield-wrapper {
  margin-bottom: 15px;

  .MuiFilledInput-root {
    overflow: hidden;
    color: $grey-900;
    border-radius: 18px;
    background-color: $grey-200;

    &:hover {
      background-color: $grey-300;
    }

    &.Mui-focused {
      background-color: $grey-300;
    }
  }
}

.material-date-range-picker {
  display: flex;
  align-items: center;
}


.mui-input {
  height: 1.4375em;
  padding: 25px 12px 8px;

  input {
    width: 100px;
  }

  .autocomplete-input {

    input {
      border: none;
      background-color: transparent;
      outline: none;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
    }
  }
}

.material-date-range-picker-wrapper {
  .MuiFormControl-root {
    width: 100% !important;
  }

  .MuiInputBase-root {
    width: unset !important;
  }

  .MuiAutocomplete-root {
    width: unset !important;
    flex: 1;

    input {
      width: 100%;

      &::placeholder {
        color: currentColor;
        opacity: 0.42;;
      }
    }
  }

  .time-separtator {
    margin-right: 10px;
  }
}

.pac-container {
  z-index: 9999;
}

.PhoneInput {
  .PhoneInputCountry {
    margin-bottom: 15px;
  }

  .material-textfield-wrapper {
    flex: 1;
  }
}

.list-item-text {
  span {
    display: flex !important;
    align-items: center;
    gap: 10px;
  }
}

.select-all-button {
  text-decoration: underline;
  height: 100%;
  text-align: left;
  font-size: 14px;
  width: 100%;
}

.material-select-wrapper {
  margin-bottom: 15px;

  .MuiFilledInput-root {
    border: none;
    overflow: hidden;
    min-width: 200px;
    color: $grey-900;
    background-color: $grey-200;
    border-radius: 18px;

    &:hover {
      background-color: $grey-300;
    }

    &.Mui-focused {
      background-color: $grey-300;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}


.mui-button {
  &.MuiButton-root {
    padding: 10px 20px;
    border-radius: 18px;
  }

  &.fourth {
    background-color: $grey-200;
    color: $grey-900;
    box-shadow: none;
    &:active{
      box-shadow: none;
    }
    &:hover{
      background-color: $grey-300;
      box-shadow: none;
    }
  }
  &.third {
    padding: 0px 20px;
    border-radius: 8px;
    box-shadow: $surround-shadow-light;
  }
}

.MuiMenu-root {
  .MuiMenu-paper {
    border-radius: 15px;
  }
}


.rounded-button {
  &.MuiButton-root {
    line-height: 0;
    min-width: unset;
    padding: 7px;
    border-radius: 999px;
  }
}


.material-radio-wrapper {
  flex: 1;

  .MuiFormGroup-root {
    justify-content: space-evenly;
    gap: 10px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  &.hide-button {
    .MuiButtonBase-root {
      display: none;
    }

    .MuiTypography-root {
      padding: 0 14px;
    }
  }
}


.address-input-wrapper {
  margin-bottom: 10px;
  position: relative;

  .address-input-contextual-action {
    position: absolute;
    right: 15px;
    top: 2px;
    z-index: 1;

    .MuiFormControlLabel-label {
      font-size: 13px;
    }
  }
}
